const search = {
  el: document.querySelector(`.js-search`),
  toggleEl: document.querySelector(`.js-search-toggle`),
  input: document.getElementById(`search-input`),
  openClass: `is-open`,

  toggle(e) {
    if (e != undefined) {
      e.preventDefault();
    }

    this.el.classList.toggle(this.openClass);

    if (this.el.classList.contains(this.openClass)) {
      setTimeout(() => {
        this.input.focus();
      }, 200);
    }
  },

  init() {
    this.toggleEl.addEventListener(`click`, this.toggle.bind(this));

    document.onkeydown = e => {
      if (this.el.classList.contains(this.openClass)) {
        if (e.keyCode === 27) {
          this.el.classList.toggle(this.openClass);
        }

        if (e.keyCode === 13) {
          this.el.submit();
        }
      }
    };
  }
};

export { search };
