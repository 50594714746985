import homeVimeo from './modules/homeVimeo';
homeVimeo();

import { share } from './modules/share';
share.init();

import { seeAll } from './modules/seeAll';
seeAll.init();

import { stickyNav } from './modules/stickyNav';
stickyNav.init();

import { menu } from './modules/menu';
menu.init();

import { search } from './modules/search';
search.init();

import { rfw } from './modules/rfw';
rfw.init();

import { backgroundVideo } from './modules/backgroundVideo';
backgroundVideo.init();

import { modal } from './modules/modal';
modal.init();

import { form } from './modules/form';
form.init();

import { ajaxNav } from './modules/ajax-navigation';
ajaxNav.init();

import { triathlonData } from './modules/triathlonData';
triathlonData.init();

import donate from './modules/donate';
donate();
